/* eslint-disable no-unused-expressions */
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Rating from '../Rating';
import Classes from './index.module.css';
import styled from 'styled-components';
import Loader from '../LoadingIndicator';

const HorizontalCarouselStyle = styled.div`
.testimonials_rating {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.testimonials_rating div,
.testimonials_rating fieldset {
    max-width: fit-content !important;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MuiRating-icon {
    color: #25a7de !important;
}
.carousel-indicators{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color:white;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
}
.carousel-indicators li.active{
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color:white;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1 !important;
    transition: opacity .6s ease;
}
.carousel-control-next, .carousel-control-prev{
    opacity:0.9 !important;
}
.MuiBox-root{
    margin-bottom:0px;
}
.carousel-inner{
    min-height:300px;
}

@media(max-width:768px){
    .carousel-control-next, .carousel-control-prev{
        display:none;
    }
    .carousel-indicators{
display:none;
    }
}
margin-top:20px;
`
const CarouselWrapper = styled.div`
.carousel-indicators {
  position: absolute;
  right: 0;
  top: 40%;
  bottom: auto;
  left: auto;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 1%;
  margin-left: 1%;
  list-style: none;
  flex-direction: column;
}
.carousel-indicators li{
      box-sizing: content-box;
      -ms-flex: 0 1 auto;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 8px;
      height: 8px;
      margin-right: 3px;
      margin-left: 3px;
      text-indent: -999px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #fff;
      background-clip: padding-box;
      border-top: none;
      border-bottom: none;
      margin-top: 10px;
      margin-bottom: 10px;
      box-shadow: 1px 1px slategrey;
      -webkit-transition: opacity .6s ease;
      transition: opacity .6s ease;
  box-shadow:1px 1px 2px rgb(0 0 0 / 90%);
  transition: opacity .6s ease;
}
.carousel-indicators li .active{
  opacity: 1;
}
.carousel-control-next, .carousel-control-prev{
  display:none !important;
}
.carousel{
  position: relative;
    width: 100%;
    background-color: #0a224278 !important;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 38%;
  opacity: 1 !important;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.carousel-caption>h3{
  color: white;
  font-size: 3em;
}
.carousel-caption>p{
    color: white;
    font-size: 18px;
  }
.carousel-inner{
  position: relative;
    width: 100%;
    overflow: hidden;
    background-color: #0a224278 !important;
}
.MuiBox-root.MuiBox-root-1 {
    display: flex;
    justify-content: center;
    text-align: center;
}
.MuiRating-root.MuiRating-readOnly {
    margin: auto !important;
}
@media(max-width:768px){
  .carousel-caption>h3{
    color: white;
    font-size: 1.8em;
  }
  .carousel-caption>p{
    color: white;
    font-size: 1em;
  }
  .carousel-caption {
    bottom: 10%;
  }
}
`
const HorizontalCarousel = (props) => {
    let filterData;

    if (props.testimonials) {
        filterData = props.testimonials.filter(ele => ele.rating >= 4.5)
    }

    let carouselData;
    let carouselimages;
    props.testimonials ?
        carouselData = filterData.slice(0, 5).map((ele, index) => {
            if (ele.rating >= 4.5) {
                let date = ele.survey_completed_on.indexOf('T')
                let Datestring = ele.survey_completed_on.toString();
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];
                let newDate = Datestring.slice(0, date)
                let d = new Date(newDate);
                let month = monthNames[d.getMonth()]
                let LastName = ele.customer_last_name
                return (
                    <Carousel.Item interval={5000} key={index}>
                        <div className={Classes.card_Style}>
                            <Row style={{ marginBottom: '25px' }}>
                                <Col md={12}>
                                    <div className={Classes.horizontal_author_details}>
                                        <Row>
                                            <h6 style={{ color: 'white', fontSize: '24px', fontWeight: 'bold', textAlign: 'center', width: '100%' }}>
                                                {ele.customer_first_name + " "} {LastName != null ? LastName.substring(0, 1) + "." : null}
                                            </h6>
                                        </Row>
                                        <Row className={Classes.testimonials_rating}>
                                            <Rating value={ele.rating} />
                                            <p style={{ fontSize: '15px', width: '100%', color: 'white', textAlign: 'center' }} >
                                                on {month} {d.getDate()}, {d.getFullYear()}
                                            </p>
                                        </Row>
                                    </div>
                                    <Row>

                                        <p className={Classes.description}>{ele.review == "." ? "Missing Link" : ele.review} <br />
                                            <br /> on <strong>{ele.agent_name}</strong> (Loan Officer)
                                        </p>

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Carousel.Item>
                )
            }
        }) : null


    props.images ?
        carouselimages = props.images.map((ele, index) => {
            let img = ele.img
            return (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={img}
                        alt="First slide"
                    />
                    <Carousel.Caption className={Classes.vertical_carousel_text}>
                        <h3>{props.carouselHeader}</h3>
                        {props.carouselText ?
                            <p>{props.carouselText}</p>
                            : null}
                    </Carousel.Caption>
                </Carousel.Item>
            )
        }) : null


    props.mobileImages ?
        carouselimages = props.mobileImages.map((ele, index) => {
            let img = ele.img
            return (
                <Carousel.Item key={index}>
                    <img
                        className="d-block w-100"
                        src={img}
                        alt="First slide"
                    />
                    <Carousel.Caption className={Classes.vertical_carousel_text}>
                        <h3>Shedding Light on Home Financing</h3>
                        <p>Get trusted guidance through your home buying or refinancing journey.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            )
        }) : null

    return (
        <div>
            {
                props.testimonials ? <HorizontalCarouselStyle>
                    <Carousel touch={true} slide={true} interval={1000} controls={false} indicators={true}>
                        {carouselData}
                    </Carousel>
                </HorizontalCarouselStyle> : props.images || props.mobileImages ? < CarouselWrapper > <Carousel touch={true} className={Classes.imgcarousel} pause={false} interval={3000} >
                    {carouselimages}
                </Carousel></CarouselWrapper> : <Loader />
            }
            { }
        </div >
    )
}

export default HorizontalCarousel;