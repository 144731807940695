import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { BorrowerLeadGenerationTool } from 'borrower-lead-generation-tool';
import styled from 'styled-components';

const StyledModalHeader = styled(Modal.Header)`
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: #00aae4 !important;
  color: #fff !important;
  font-weight: 500 !important;
`;

const StyledModalBody = styled(Modal.Body)`
  .borrowerLeadGeneration .blg-col-0 {
    display: none;
  }
`;

const LeadFormModal = (
  { loanOfficerId, 
    branchCode, 
    leadSrc, 
    loanPurpKeyword,
    formHeading,
    showLeadFormModal,
    onHide,
    statesDomain }) => {

    // stores the input field reference in a way that allows us to conditionally render
    // content or scroll to the element on button click.
    const [node, setNode] = useState(null);

    // watches for changes in the form.
    const formRef = useCallback(node => {
      // node refers to the name input field in the BorrowerLeadGenerationTool
      setNode(node);
    }, []);

    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLeadFormModal}
        onHide={onHide}
      >
        <StyledModalHeader closeButton>
          <Modal.Title>{formHeading}</Modal.Title>
        </StyledModalHeader>
        <StyledModalBody>
          <BorrowerLeadGenerationTool
            leadSource={leadSrc}
            loanOfficerUid={loanOfficerId}
            branch={branchCode}
            loanPurposeKeyword={loanPurpKeyword}
            statesDomain={statesDomain}
            ref={formRef}
            language="en"
            domainNameConsent="swmc.com"
          />
        </StyledModalBody>
      </Modal>
    );
}

export default LeadFormModal;