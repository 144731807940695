import React, { Suspense } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ScrollAnimation from 'react-animate-on-scroll';
import { lazy } from '@loadable/component';
import styled from 'styled-components';
import PhoneIcon from '../../Images/phoneIcon.png';
import HeadsetIcon from '../../Images/headsetIcon.png';
import HandIcon from '../../Images/handIcon.png';
import MailIcon from '../../Images/mailIcon.png';
import styles from '../Body/body.module.css';

const Image = lazy(() => import('react-bootstrap/Image'));

const ScrollAnimationWrapper = styled.div`
  .a-a {
    text-decoration: none;
  }
  .a-a:hover {
    text-decoration: underline;
  }
`;

export default function CallUs() {
  return (
    <ScrollAnimationWrapper>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <Container fluid={true} className={styles.phoneNoContainer}>
            <Row>
            <Col
                md={6}
                lg={4}
                sm={12}
                style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                }}
            >
                <div className={styles.callHeader}>
                <h3>Call Us</h3>
                <p style={{ maxWidth: '420px' }}>
                    Phone calls between you and any representatives of Sun
                    West Mortgage Company, Inc. shall be recorded and
                    monitored for quality assurance and training purposes.
                </p>
                </div>
            </Col>
            <Col
                md={6}
                lg={3}
                sm={12}
                style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                }}
            >
                <Suspense fallback={<div>Loading...</div>}>
                <Image alt="Phone icon" src={PhoneIcon} fluid />
                </Suspense>
                <div className={styles.subHead}>
                <h4>
                    <strong title="Contact Sun West Management through Phone or Email">
                    Mortgage Hotline
                    </strong>
                </h4>
                <a
                    href="tel:+1-844-342-5796"
                    title="If you are having a negative experience, Sun West Mortgage is waiting to speak with you. Call 1-844-342-5796."
                    className="a-a"
                >
                    844-DIAL-SWM
                </a>
                <a
                    href="mailto:management@swmc.com"
                    title="Contact Sun West Mortgage through Email"
                    aria-label="Click the icon to Contact Sun West Mortgage through Email"
                >
                    <Suspense fallback={<div>Loading...</div>}>
                    <Image
                        className={styles.mailIcon}
                        alt="logo-image"
                        src={MailIcon}
                    />
                    </Suspense>
                </a>
                </div>
            </Col>

            <Col
                md={6}
                lg={3}
                sm={12}
                style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                }}
            >
                <Suspense fallback={<div>Loading...</div>}>
                <Image alt="Headset icon" src={HeadsetIcon} fluid />
                </Suspense>
                <div className={styles.subHead}>
                <h4 title="Contact Customer Care Team through Phone or Email">
                    <strong>Customer Care</strong>
                </h4>
                <a
                    href="tel:+1-844-978-6937"
                    title="If you have a loan in process and have any questions related to your loan, Customer Care Team is waiting to speak with you. Call 1-844-978-6937."
                    className="a-a"
                >
                    844-978-6937
                </a>
                <a
                    href="mailto:customercare@swmc.com"
                    title="Contact Sun West Customer Care Team through Email."
                    aria-label="Click the icon  to Contact Sun West Customer Care Team through Email"
                >
                    <Suspense fallback={<div>Loading...</div>}>
                    <Image
                        className={styles.mailIcon}
                        alt="logo-image"
                        src={MailIcon}
                    />
                    </Suspense>
                </a>
                </div>
            </Col>

            <Col
                md={6}
                lg={2}
                sm={12}
                style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                }}
            >
                <Suspense fallback={<div>Loading...</div>}>
                <Image alt="Hand icon" src={HandIcon} fluid />
                </Suspense>

                <div className={styles.subHead}>
                <h4 title="Contact Loan Servicing Agent through Phone or Email">
                    <strong>Loan Servicing</strong>
                </h4>
                <a
                    href="tel:+1-800-345-7884"
                    title="If you have inquiries on your existing account or payment-related information, Loan Servicing Agent is waiting to speak with you. Call 1-800-345-7884."
                    className="a-a"
                >
                    800-345-7884
                </a>
                <a
                    href="https://www.sunwestmortgage.com/contact-loan-servicing"
                    target="_blank"
                    rel="noopener"
                    title="Click to complete and submit our online Loan Servicing Form"
                >
                    <Suspense fallback={<div>Loading...</div>}>
                    <Image
                        className={styles.mailIcon}
                        alt="logo-image"
                        src={MailIcon}
                    />
                    </Suspense>
                </a>
                </div>
            </Col>
            </Row>
        </Container>
        </ScrollAnimation>
    </ScrollAnimationWrapper>
  )
}
