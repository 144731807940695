import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import Loader from '../LoadingIndicator';
import HorizontalCarousel from '../HorizontalCarousel';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from '../Body/body.module.css';

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState('');

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_SWMC_TESTIMONIALS, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        setTestimonials(response.data);
      })
      .catch((error) => {
        console.error('Error: ', error);
      });

    if (testimonials) {
      if (testimonials.length === 0) {
        if (window.innerWidth > 768) {
          setTimeout(() => {}, 2500);
        }
      }
    } else {
      setTimeout(() => {}, 4000);
    }
  }, []);

  let TestimonialToShow = '';
  if (testimonials) {
    if (testimonials.length > 0 && testimonials !== 'Survey Not Found') {
      TestimonialToShow = testimonials.map((ele) => {
        return ele;
      });
    }
  }

  return (
    <>
      {testimonials && (
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Suspense fallback={<div>Loading...</div>}>
            <div
              className={styles.testimonialsContainerText}
              style={{ marginBottom: '20px' }}
            >
              <h2 style={{ textAlign: 'center', color: 'white' }}>
                Reviews from People like You
              </h2>
              <div className={styles.testimonialsBorderDiv}>
                {TestimonialToShow.length >= 1 ? (
                  <HorizontalCarousel testimonials={TestimonialToShow} />
                ) : TestimonialToShow[0] === 'Survey Not Found' ? (
                  <p
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      marginTop: '30px',
                      paddingBottom: '40px',
                    }}
                  >
                    Survey Not Found
                  </p>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </Suspense>
        </ScrollAnimation>
      )}
    </>
  );
}
