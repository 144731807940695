import './App.css';
import NavBar from './components/Navbar';
import Body from './components/Body';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/FooterNew';

function App() {

  return (
    <>
      <NavBar />
      <Body />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
