/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState, Suspense } from 'react';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Reveal from 'react-reveal/Reveal';
import Loader from '../LoadingIndicator';
import { lazy } from '@loadable/component';
import parse from 'html-react-parser';
import styles from '../Body/body.module.css';
import RightArrow from '../../Images/rightArrow.png';
import LeftArrow from '../../Images/leftArrow.png';

const Image = lazy(() => import('react-bootstrap/Image'));

export default function Blog() {
  const [activeBlog, setActiveBlog] = useState(0);
  const [effect, setEffect] = useState('slideInLeft');
  const [blogPosts, setBlogPosts] = useState('');
  let dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric' };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SWMC_BLOG, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        setBlogPosts(response.data);
      })
      .catch((error) => {
        console.error('Error: ', error);
      });
  }, []);

  const blogRedirect = (ele) => {
    window.open(ele);
  };

  const nextBlog = () => {
    if (activeBlog < 2) {
      setActiveBlog(activeBlog + 1);
    } else {
      setActiveBlog(0);
    }
    setEffect('slideInLeft');
  };
  const prevBlog = () => {
    if (activeBlog > 0) {
      setActiveBlog(activeBlog - 1);
    } else {
      setActiveBlog(2);
    }
    setEffect('slideInRight');
  };

  let blogs;
  if (blogPosts.length > 0) {
    let count = 1;
    blogs = blogPosts.slice(activeBlog, activeBlog + 1).map((ele, index) => {
      count = count++;
      return (
        <div
          onClick={() =>
            blogRedirect(
              `https://www.swmc.com/blog/post-.php?tt=${ele.file_name.replace(
                '.php',
                ''
              )}`
            )
          }
          style={{ maxWidth: '400px', border: 'none' }}
          key={ele.id}
        >
          <Card style={{ width: 'auto' }} className={styles.newBlogCards}>
            <label className={styles.blogLabel}>Blog</label>
            <Reveal effect={effect} distance="10px">
              <Card.Title className={styles.newBlogTitle}>
                {ele.title
                  .replace('â€œ', '"')
                  .replace('â€', '"')
                  .replace('â€™', "'")
                  .replace('â€“', '-')}
              </Card.Title>
              <Card.Body style={{ padding: '0' }}>
                <div
                  className={`${styles.newBlogCardBodyText} blogContents`}
                >
                  {parse(ele.content.substring(0, 150))}
                  {new Date(blogPosts[0].date).toLocaleString(
                    'en-US',
                    dateOptions
                  )}
                </div>
                <Button className={styles.blogButton}>Read More</Button>
              </Card.Body>
            </Reveal>
          </Card>
        </div>
      );
    });
  }

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Container fluid={true} className={styles.blogContainer}>
          <Row style={{ margin: '0px' }} className={styles.blogRow}>
            <Col sm={6} className={styles.blogColumn}>
              <div className={styles.mappedBlog}>
                <Image
                  onClick={prevBlog}
                  style={{
                    width: '50px',
                    margin: '5px',
                    cursor: 'pointer',
                  }}
                  alt="card-logo"
                  src={LeftArrow}
                />
                {blogPosts.length > 0 ? blogs : <Loader />}
                <Image
                  onClick={nextBlog}
                  style={{
                    width: '50px',
                    margin: '5px',
                    cursor: 'pointer',
                  }}
                  alt="card-logo"
                  src={RightArrow}
                />
              </div>
              <div className={styles.mobilemappedBlog}>
                {blogPosts.length > 0 ? blogs : <Loader />}
                <div style={{ textAlign: 'center' }}>
                  <Image
                    onClick={prevBlog}
                    style={{
                      width: '50px',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                    alt="card-logo"
                    src={LeftArrow}
                  />
                  <Image
                    onClick={nextBlog}
                    style={{
                      width: '50px',
                      margin: '5px',
                      cursor: 'pointer',
                    }}
                    alt="card-logo"
                    src={RightArrow}
                  />
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className={styles.blogContent}>
                <div className={styles.header_border}> </div>
                <h2>Latest News & Blogs</h2>
                <p>Your resource on all things home and mortgage. </p>
                <a
                  href="https://www.swmc.com/blog"
                  target="_blank"
                  rel="noopener"
                  style={{
                    width: '40%',
                    textAlign: 'center',
                    textDecoration: 'none',
                  }}
                >
                  View More
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Suspense>
    </>
  );
}
