/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styles from './index.module.css';
import { Button } from 'react-bootstrap';

const Banner = () => {
  return (
    <div className={styles.hero_image}>
      <div className={styles.contentDiv}>
        <div className={styles.headerText}>
          <h1>
            Your <span className={styles.trueHome}>True Home</span>
            <br />
            Is Always Within
          </h1>
        </div>
        <div className={styles.btnRow}>
          <a
            href="https://www.swmc.com/ApplyNow/?extLeadSource=INHNPORT-070&loginByUsingView=GS&extLoanPurpose=PURCHASE"
            target="_blank"
          >
            <Button className={styles.button}>Purchase</Button>
          </a>
          <a
            href="https://www.swmc.com/ApplyNow/?extLeadSource=INHNPORT-070&loginByUsingView=GS&extLoanPurpose=LIMITED%20CO"
            target="_blank"
          >
            <Button className={styles.button}>Refinance</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
